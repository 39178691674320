import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import GUI from 'lil-gui'

/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

/**
 * Particles
 */
//Geometry
const particlesGeometry = new THREE.BufferGeometry()
const particlescount = 500000
const particleTextures = textureLoader.load('/textures/particles/2.png')

const positions = new Float32Array(particlescount * 3)
const initialPositions = new Float32Array(particlescount * 3)

for(let i = 0;i < particlescount * 3; i++) {
    positions[i] = (Math.random() - 0.5) * 150
    initialPositions[i] = positions[i]
}

particlesGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(positions,3)
)
//Material
const particleMaterial = new THREE.PointsMaterial({
    size: 0.1,
    sizeAttenuation: true,
    map: particleTextures,
    transparent: true,
    alphaMap: particleTextures,
    // alphaTest: 0.01
    // depthTest: false
    // depthWrite: false
})

// Points
const particles = new THREE.Points(particlesGeometry, particleMaterial)
scene.add(particles)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 10
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

// Load font and create text geometry
const fontLoader = new FontLoader()
let targetPositions = new Float32Array(particlescount * 3)
let textMesh
let wireframeMaterial

fontLoader.load('/fonts/Roboto_Bold.json', (font) => {
    const textGeometry = new TextGeometry('Comming Soon', {
        font: font,
        size: 1,
        height: 0.2,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 5
    })

    textGeometry.center()

    const textVertices = textGeometry.attributes.position.array
    const textVertexCount = textVertices.length / 3

    // Distribute particles more evenly across all vertices
    for (let i = 0; i < particlescount; i++) {
        const vertexIndex = Math.floor(Math.random() * textVertexCount) * 3
        targetPositions[i * 3] = textVertices[vertexIndex]
        targetPositions[i * 3 + 1] = textVertices[vertexIndex + 1]
        targetPositions[i * 3 + 2] = textVertices[vertexIndex + 2]
    }

    // Create wireframe mesh
    const wireframeGeometry = new THREE.WireframeGeometry(textGeometry)
    wireframeMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0 })
    textMesh = new THREE.LineSegments(wireframeGeometry, wireframeMaterial)
    textMesh.visible = false
    scene.add(textMesh)
})

// Interpolation speed variable
const transitionSpeed = 0.008

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    let animationComplete = true

    // Update particle positions with random movement and transition to text geometry
    for (let i = 0; i < particlescount * 3; i += 3) {
        // Interpolate towards text geometry shape
        const dx = (targetPositions[i] - positions[i]) * transitionSpeed
        const dy = (targetPositions[i + 1] - positions[i + 1]) * transitionSpeed
        const dz = (targetPositions[i + 2] - positions[i + 2]) * transitionSpeed

        positions[i] += dx
        positions[i + 1] += dy
        positions[i + 2] += dz

        if (Math.abs(dx) > 0.007 || Math.abs(dy) > 0.007 || Math.abs(dz) > 0.007) {
            animationComplete = false
        }
    }
    particlesGeometry.attributes.position.needsUpdate = true

    // Show wireframe after animation completes
    if (animationComplete && textMesh) {
        textMesh.visible = true
        if (wireframeMaterial.opacity < 1) {
            wireframeMaterial.opacity += 0.0085
            particleMaterial.opacity -= 0.0095
        }
        if (wireframeMaterial.opacity > 1) {
            particles.visible = false
        }
    }


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()